.carousel {
    box-sizing: border-box;
    height: 100%;
    overflow: hidden;
    position: relative;
    width: 100%;
}

.pagesContainer {
    height: 100%;
    overflow: hidden;
    width: 100%;
}

.pages {
    display: flex;
    height: 100%;
    position: relative;
    transition-timing-function: cubic-bezier(0, 0, 0.25, 1);
    padding: 0;
}

.horizontalPages {
    left: 0;
}

.verticalPages {
    flex-direction: column;
    top: 0;
}

.page {
    flex-shrink: 0;
    height: 100%;
    overflow: hidden;
    box-sizing: border-box;
    margin: 2px;
    width: auto;
}

.page > * {
    height: 100%;
    max-width: 100%;
}

.next {
    border-radius: 2px 0 0 2px;
    right: 0;
}

.next img {
    margin-left: 3px;
    transform: scaleX(-1);
}

.prev {
    border-radius: 0 2px 2px 0;
    left: 0;
}

.prev img {
    margin-left: -3px;
}

.navigation {
    bottom: 10px;
    display: flex;
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
    z-index: 2;
    padding: 0;
    list-style: none;
}

.navigation li button {
    background-color: #fff;
    border-radius: 50%;
    border: none;
    cursor: pointer;
    font-size: 0;
    height: 10px;
    outline: none;
    padding: 0;
    width: 10px;
}

.navigation li.activeNavigationButton button {
    background-color: #e62e04;
}

.navigation li + li button {
    margin-left: 10px;
}

.control {
    align-items: center;
    background-color: rgba(0, 0, 0, 0.4);
    border: none;
    cursor: pointer;
    display: flex;
    font-size: 0;
    height: 50px;
    justify-content: center;
    margin-top: -25px;
    outline: none;
    padding: 0;
    position: absolute;
    top: 50%;
    width: 30px;
    z-index: 2;
}

.controlHidden {
    display: none;
}

.sliderButton {
    height: 100%;
    position: absolute;
    top: calc(50% - 25px);
    width: 100%;
}

.nextButton {
    transform: rotate(180deg);
}

.carousel.carousel {
    overflow: visible;
}

.control {
    background-color: transparent;
    display: flex;
    height: 16px;
    top: calc(50% + 34px);

    @media (max-width: 1080px) {
        display: none;
    }
}

.sliderButton.sliderButton {
    background: url("https://g.alicdn.com/ae-fe-ru/arrow-svg/1.0.0/arrow.svg") no-repeat;
    height: 16px;
    opacity: unset;
}

.next.next {
    right: -25px;
}

.prev.prev {
    left: -25px;
}

.navigation.navigation {
    display: none;
}

.dots {
    padding: 16px;
    display: flex;
    justify-content: center;
    height: 16px;
}

.dot {
    width: 8px;
    height: 8px;
    border-radius: 8px;
    background-color: #222;
    opacity: 0.4;
}

.dot + .dot {
    margin-left: 4px;
}

.dotActive {
    opacity: 1;
}
