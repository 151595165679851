.container {
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: var(--marginMobile);
}

.slider.slider {
    position: relative;
}

.frame {
    display: flex;
    width: 100%;
    max-width: 1376px;
    position: relative;
    height: 286px;
    background: #e1e1e1;
}

.dots.dots {
    position: absolute;
    bottom: 12px;
    left: 0;
    right: 0;
}

.dots.dots > div {
    border: 6px solid white;
}

.control.control {
    @media (max-width: 1080px) {
        display: block;
    }

    @media (max-width: 520px) {
        display: none;
    }
}

.prev.prev {
    left: 15px;
    opacity: 0.8;
}

.next.next {
    right: 15px;
    opacity: 0.8;
}

.next.next,
.prev.prev {
    &:hover {
        opacity: 1;
    }
}

.container {
    .prev.prev {
        visibility: hidden;
    }

    .next.next {
        visibility: hidden;
    }
}

.container:hover {
    transition: visibility ease 0.5s;

    .prev.prev {
        visibility: visible;
    }

    .next.next {
        visibility: visible;
    }
}

.controlsImage.controlsImage {
    background: url("https://st.aliexpress.ru/mixer-storage/front-icons/arrow.svg") no-repeat;
    height: 32px;
    width: 32px;
}

.page {
    width: 100%;
}

@media (--snow-tablet) {
    .container {
        margin: var(--marginTablet);
    }
}

@media (--snow-desktop) {
    .container {
        margin: var(--marginDesktop);
    }
}

@media (max-width: 1280px) {
    .frame {
        max-width: 1216px;
        height: 253px;
    }
}

@media (max-width: 1024px) {
    .frame {
        max-width: 960px;
        height: 194px;
    }
}

@media (max-width: 768px) {
    .tablet {
        display: block;
    }

    .frame {
        max-width: 704px;
        height: 143px;
    }
}

@media (max-width: 520px) {
    .frame {
        max-width: 489px;
        height: 233px;
        border-radius: 16px;
    }

    .frameBigImage {
        height: unset;
    }
}

@media (max-width: 398px) {
    .frame {
        max-width: 365px;
        height: 196px;
    }

    .frameBigImage {
        height: unset;
    }
}

@media (max-width: 280px) {
    .frame {
        max-width: 248px;
        height: 167px;
    }

    .frameBigImage {
        height: unset;
    }
}
