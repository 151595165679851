.desktopContainer {
    visibility: collapse;
}
.text-in-image {
    width: calc(100% - 20px);
    max-height: 155px;
    object-fit: contain;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 20px;
}
.title {
    text-transform: uppercase;
    font-family: "Ali_ver.18_Lighter-Rounded", Inter, sans-serif;
    font-size: 56px;
    line-height: 96%;
    letter-spacing: -0.343px;
    margin: 0;
}

.title-single {
    font-size: 64px;
}

.subtitle {
    font-size: 17px;
    font-weight: normal;
    line-height: 117%;
    margin: 0;
}

.content-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.content-wrapper-mobile {
    display: none;
}

.left-block {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    padding: 24px 12px;
    flex-basis: 481px;
    justify-content: center;
    align-items: center;
}

.right-block {
    box-sizing: border-box;
    display: flex;
    position: relative;
    padding: 24px 12px;
    overflow: hidden;

    flex-basis: 895px;
}

.inner-wrapper {
    box-sizing: border-box;
    width: 100%;
    display: flex;
    flex-grow: 1;
    height: 286px;
}

.outer-wrapper {
    visibility: visible;
    box-sizing: border-box;
    display: flex;
    width: 100%;
    max-width: 1376px;
}

.image {
    position: absolute;
    height: 286px;
    margin: auto;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
}

.ad-button-wrapper {
    position: absolute;
    bottom: 19px;
    right: 0;
}

.ad-tooltip-wrapper {
    width: 320px;
}

.ad-tooltip {
    border: none;
    z-index: 100;
    margin-right: 2px;
    -webkit-box-shadow: 0 0 14px -4px rgba(34, 60, 80, 0.2);
    -moz-box-shadow: 0 0 14px -4px rgba(34, 60, 80, 0.2);
    box-shadow: 0 0 14px -4px rgba(34, 60, 80, 0.2);
}

.ad-button {
    display: flex;
    height: fit-content;
    font-size: 9px;
    padding: 5px 0 5px 0;
    border-radius: 4px 0 0 4px;
    background: rgba(235, 235, 237, 0.65);
    color: #898993;
    line-height: 12px; /* 133.333% */
    letter-spacing: 0.18px;
    border: none;
    writing-mode: vertical-rl;
    outline: none;
}

.ad-modal-content {
    padding: 0 8px 8px;
}

.ad-modal-header {
    padding: 25px 8px 0;
}

.ad-modal-title {
    text-align: left;
}

.image-mobile {
    position: absolute;
    height: 286px;
    margin: auto;
}

.button {
    border: none;
    cursor: pointer;
    padding: 12px 16px;
    border-radius: 100px;
    width: 245px;
    font-size: 15px;
    line-height: 120%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.subtitle-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 12px;
    text-align: center;
}

.title-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.arrow {
    margin-left: 2px;
}

.text-wrapper {
    margin-bottom: 24px;
}

.arrow-normal {
    display: flex;
    justify-content: center;
}

.arrow-small {
    display: none;
    justify-content: center;
}

.arrow-x-small {
    display: none;
    justify-content: center;
}

.title-with-arrow {
    display: inline-block;
    padding-right: 18px;
    padding-left: 18px;
}

.title-with-arrow:after {
    display: inline-block;
    margin-bottom: 5px;
    vertical-align: middle;
    margin-right: -18px;
    content: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjUiIHZpZXdCb3g9IjAgMCAyNCAyNSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEyLjc1MjkgNS4yOTQ5MkMxMy4xNDIzIDQuOTAzMjggMTMuNzc1NSA0LjkwMTQ3IDE0LjE2NzEgNS4yOTA4OEwyMC43MDE1IDExLjc4ODFDMjAuODk4MiAxMS45ODM2IDIwLjk5NjUgMTIuMjQwNyAyMC45OTY0IDEyLjQ5NzhDMjAuOTk2NSAxMi43NTQ4IDIwLjg5ODIgMTMuMDExOSAyMC43MDE1IDEzLjIwNzRMMTQuMTY3MSAxOS43MDQ2QzEzLjc3NTUgMjAuMDk0IDEzLjE0MjMgMjAuMDkyMiAxMi43NTI5IDE5LjcwMDZDMTIuMzYzNSAxOS4zMDkgMTIuMzY1MyAxOC42NzU4IDEyLjc1NjkgMTguMjg2NEwxNy41MjgxIDEzLjU0MjRMNC4wMDI5MiAxMy41NDMyQzMuNDQ5MDYgMTMuNTQzMyAzLjAwMDA0IDEzLjA5NjkgMyAxMi41NDYyQzIuOTk5OTYgMTEuOTk1NSAzLjQ0ODkzIDExLjU0OSA0LjAwMjc5IDExLjU0OUwxNy42MjM2IDExLjU0ODFMMTIuNzU2OSA2LjcwOTEyQzEyLjM2NTMgNi4zMTk3MiAxMi4zNjM1IDUuNjg2NTUgMTIuNzUyOSA1LjI5NDkyWiIgZmlsbD0iYmxhY2siLz4KPC9zdmc+Cg==");
}

.big-image {
    width: 100%;
    max-height: 146px;
}

@media (max-width: 1280px) {
    .outer-wrapper {
        max-width: 1216px;
    }

    .inner-wrapper {
        display: flex;
        flex-grow: 1;
        height: 253px;
    }

    .text-wrapper {
        margin-bottom: 20px;
    }

    .title {
        font-size: 48px;
    }

    .title-single {
        font-size: 56px;
    }

    .left-block {
        flex-basis: 424px;
        min-width: 350px;
    }

    .right-block {
        flex-basis: 792px;
    }

    .image {
        height: 253px;
        flex-basis: 792px;
    }
}

@media (max-width: 1024px) {
    .outer-wrapper {
        max-width: 960px;
    }

    .button {
        width: 220px;
    }

    .arrow-normal {
        display: none;
    }

    .arrow-small {
        display: flex;
    }

    .inner-wrapper {
        display: flex;
        height: 194px;
    }

    .text-in-image {
        max-height: 115px;
    }

    .title {
        font-size: 32px;
    }

    .title-single {
        font-size: 40px;
    }

    .image {
        height: 194px;
        flex-basis: 792px;
    }

    .left-block {
        flex-basis: 350px;
        min-width: 254px;
    }

    .right-block {
        flex-basis: 610px;
    }
}

@media (max-width: 768px) {
    .outer-wrapper {
        max-width: 704px;
    }

    .button {
        width: 140px;
        height: 32px;
    }

    .arrow-small {
        display: none;
    }

    .arrow-x-small {
        display: flex;
    }

    .inner-wrapper {
        overflow: hidden;
        display: flex;
        height: 143px;
    }

    .text-in-image {
        max-height: 90px;
    }

    .text-wrapper {
        margin-bottom: 12px;
    }

    .title {
        font-size: 24px;
    }

    .title-single {
        font-size: 32px;
    }

    .subtitle {
        font-size: 13px;
    }

    .subtitle-wrapper {
        margin-top: 8px;
    }

    .button {
        font-size: 13px;
    }

    .arrow {
        height: 12px;
        width: 12px;
    }

    .left-block {
        flex-basis: 254px;
        min-width: 169px;
        padding: 8px 12px;
    }

    .right-block {
        flex-basis: 450px;
    }

    .image {
        height: 143px;
    }
}

@media (min-width: 521px) {
    .big-image {
        display: none;
    }

    .image {
        display: block;
    }

    .image-mobile {
        display: none;
    }
}

@media (max-width: 520px) {
    .content-wrapper-mobile {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 97px;
        padding: 12px 8px 0 8px;
    }

    .title {
        font-size: 29px;
    }

    .subtitle-wrapper {
        margin-top: 6px;
    }

    .subtitle {
        font-size: 13px;
    }

    .content-wrapper-mobile {
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        align-items: center;
        width: 100%;
    }

    .outer-wrapper {
        max-width: 489px;
    }

    .inner-wrapper {
        border-radius: 12px;
        overflow: hidden;
        display: flex;
        height: 231px;
    }

    .innerWrapperNormalHeight {
        height: 233px;
    }

    .big-image-inner-wrapper {
        height: unset;
    }

    .left-block {
        display: none;
    }

    .right-block {
        flex-basis: 489px;
        padding: 0;
    }

    .image {
        display: none;
    }

    .image-mobile {
        height: 136px;
        transform: translate(-50%, 0);
        bottom: 0;
        left: 50%;
    }

    .big-image-outer-wrapper {
        height: unset;
    }

    .big-image-inner-wrapper {
        height: unset;
    }

    .big-image-inner-wrapper > .left-block,
    .big-image-inner-wrapper > .right-block {
        display: none;
    }
}

@media (max-width: 398px) {
    .inner-wrapper {
        height: 194px;
    }

    .innerWrapperNormalHeight {
        height: 196px;
    }

    .outer-wrapper {
        max-width: 365px;
    }

    .image-mobile {
        height: 98px;
    }

    .big-image-outer-wrapper {
        height: unset;
    }

    .big-image-inner-wrapper {
        height: unset;
    }

    .ad-button-big-image {
        font-size: 7px;
    }
}

@media (max-width: 280px) {
    .outer-wrapper {
        max-width: 248px;
    }

    .title {
        font-size: 25px;
    }

    .subtitle {
        font-size: 13px;
    }

    .inner-wrapper {
        height: 165px;
    }

    .image-mobile {
        height: 70px;
    }

    .big-image-outer-wrapper {
        height: unset;
    }

    .big-image-inner-wrapper {
        height: unset;
    }

    .ad-button-big-image {
        font-size: 5px;
    }
}
