.ad-head {
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
}

.ad-content {
    display: flex;
}

.ad-block {
    border-radius: 12px;
    padding: 12px;
    background: rgba(34, 34, 34, 0.04);
}

.ad-info {
    display: flex;
    flex-grow: 1;
    margin-right: 8px;
}

.ad-token {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    transition: background-color 0.25s linear;
    cursor: pointer;
}

.ad-token:hover {
    background: rgba(34, 34, 34, 0.25);
}

.ad-token:active {
    background: rgba(34, 34, 34, 0.04);
}

@media (max-width: 280px) {
    .ad-content {
        flex-direction: column;
    }

    .ad-info {
        display: flex;
        flex-grow: 1;
        margin-right: 0;
        margin-bottom: 16px;
    }

    .ad-token {
        flex-grow: 1;
    }
}
